
const host = 'auctionethiopia.et'
// const host = 'test.auctionethiopia.net'
const WsUrl = 'wss://auctionethiopia.et/ws/top-bid'
// const WsUrl = 'wss://test.auctionethiopia.net/ws/top-bid'

const base_url = 'https://' + host + '/api/'
// const recaptcha_site_key = '6LfsqPgmAAAAAOpehTxcOn605Ufm82d4r023jr3-'
const recaptcha_site_key = '6Ldf62gpAAAAAP8VjDlY8ltFeutxOzaBNFeMyWTo'
// const googleMaps_ApiKey = 'AIzaSyAdIFpV6ADuHKNxMeex8rO_fJ5z_PWa7WM'
const googleMaps_ApiKey = 'AIzaSyDnB15_SDVKh5_N-4hkSHdFFRmtwYC4hCo'

export { base_url, WsUrl, recaptcha_site_key, googleMaps_ApiKey }